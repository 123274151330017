import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";

export class ActivityClass {
  constructor(
    readonly id?: string,
    readonly en?: string,
    readonly de?: string,
    readonly wasUsed?: boolean,
    readonly randomizer?: string,
    readonly lastUsed?: Timestamp,
    readonly imageLink?: string,
    readonly alt?: string,
    readonly externalContentLink?: string,
  ) {}

  static cloneWithoutId(activity: Activity): Activity {
    const clonedActivity = { ...activity };
    delete clonedActivity["id"];
    return clonedActivity;
  }

  static removeUndefinedFields(activity: Activity): Activity {
    const clonedActivity = { ...activity };
    activityAttributes.forEach((attribute) => {
      // @ts-ignore
      if (activity[attribute] === undefined) {
        // @ts-ignore
        delete clonedActivity[attribute];
      }
    });
    return clonedActivity;
  }
}

export interface Activity extends ActivityClass {}

export const activityAttributes: string[] = Object.keys(new ActivityClass());

export const activityConverter = {
  toFirestore(activity: Activity): Activity {
    return ActivityClass.cloneWithoutId(activity);
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Activity>,
    options: SnapshotOptions,
  ): Activity {
    const data = snapshot.data(options)!;
    return new ActivityClass(
      snapshot.id,
      data.en,
      data.de,
      data.wasUsed,
      data.randomizer,
      data.lastUsed,
      data.imageLink,
      data.alt,
      data.externalContentLink,
    );
  },
};
