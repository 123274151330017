import styles from "./Imprint.module.css";

export default function Imprint() {
  return (
    <div className="impressum">
      <div className={styles.content}>
        <h1 className={styles.title} id="h.l87etcdsnpkf">
          Impressum
        </h1>
        <p className={styles.subtitle}>von Daily Kids Activities</p>
        <p className={styles.dateLine}>(Stand: 05.12.2021)</p>
        <h2 className={styles.sectionTitle} id="h.c19lvfvf7mzu">
          Pflichtangaben gemäß § 5 TMG
        </h2>

        <address className={styles.ownerInfo}>
          <p className={styles.addressLine}>Daily Kids Activities</p>
          <p className={styles.addressLine}>Vladislav Lisitsyn</p>
          <p className={styles.addressLine}>Engadiner Straße 4</p>
          <p className={styles.addressLine}>81475, München</p>
          <p className={styles.addressLine}>Deutschland</p>
          <p className={styles.addressLine}>
            E-Mail:{" "}
            <a href="mailto:info@daily-kids-activities.com">
              info@daily-kids-activities.com
            </a>
          </p>
        </address>

        <h2 className={styles.sectionTitle} id="h.a4jh49xqvyjr">
          Streitschlichtungsplattform der EU
        </h2>
        <p className={styles.paragraph}>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            className={styles.link}
            href="https://ec.europa.eu/consumers/odr"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr
          </a>
        </p>
        <p className={styles.paragraph}>
          Wir sind nicht verpflichtet und nicht bereit, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </p>
        <h2 className={styles.sectionTitle} id="h.77oacpkaeeiw">
          Bildquellennachweis
        </h2>
        <p className={styles.paragraph}>
          Wir sind Inhaber der Rechte an allen Bildern und Fotos auf unserer
          Website. Alle Bilder und Fotos dürfen weder kopiert noch in sonstiger
          Weise verwertet werden.
        </p>
      </div>
    </div>
  );
};
