import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  replace,
  Route,
  RouterProvider,
} from "react-router-dom";
import { fetchActivitiesForToday } from "./ActivitiesService";
import App from "./App";
import DailyActivities from "./DailyActivities";
import Error from "./Error";
import Imprint from "./Imprint";
import NavigationBarLayout from "./NavigationBarLayout";
import NotFound, { notFoundErrorResponse } from "./NotFound";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import { BlockPostsOverview } from "./blog/BlockPostsOverview";
import BlogPost from "./blog/BlogPost";
import { getBlogPost, getBlogPosts } from "./blog/BlogPostsService";
import i18n, { supportedLanguages } from "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      {/* Redirect root to the user's language */}
      <Route index loader={() => replace(i18n.language)} />

      {/* Language-specific routes */}
      <Route
        path=":lang"
        errorElement={<Error />}
        loader={({ params }) => {
          // @ts-ignore
          if (!supportedLanguages.includes(params.lang)) {
            throw notFoundErrorResponse
          }
          return null;
        }}
      >
        {/* Home Page */}
        <Route
          index
          element={<DailyActivities />}
          loader={() => defer({ activities: fetchActivitiesForToday() })}
        />

        <Route element={<NavigationBarLayout />}>
          {/* Static Pages */}
          <Route path="imprint" element={<Imprint />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<TermsOfService />} />

          {/* Blog Routes */}
          <Route path="blog">
            <Route
              index
              element={<BlockPostsOverview />}
              loader={(props) => defer({ blogPosts: getBlogPosts(props) })}
            />
            <Route
              path=":slug"
              element={<BlogPost />}
              loader={(props) => defer({ blogPost: getBlogPost(props) })}
            />
          </Route>

          {/* Catch-all NotFound Route for :lang */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>

      {/* Top-level Catch-all NotFound Route */}
      <Route element={<NavigationBarLayout />}>
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* {isEmulatorRunning && (
        <Route path="/activities" element={<ActivitiesOverview />} />
      )}
      {isEmulatorRunning && (
        <Route path="/signin" element={<SignInScreen />} />
      )} */}
    </Route>,
  ),
);

// @ts-ignore
createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
