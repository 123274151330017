import { Link } from "react-router-dom";
import scissors from "./pictures/scissors.svg";
import styles from "./NavigationBar.module.css";

export default function NavigationBar() {
  return (
    <header className={styles.header} id="header">
      <Link to="/">
        <img src={scissors} alt="Logo Home" />
        Kids Activities
      </Link>
    </header>
  );
}
