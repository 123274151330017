import { useTranslation } from "react-i18next";
import styles from "./Error.module.css";
import { json } from "react-router-dom";
import { addRobotsTag } from "./Error";

export default function NotFound() {
  const { t } = useTranslation();

  addRobotsTag()

  return (
    <>
      <div className={styles.container}>
        <h1>{t("pageNotFoundTitle")}</h1>
        <p>{t("pageNotFoundDescription")}</p>
      </div>
    </>
  );
}

export const notFoundErrorResponse = json(
  { titleKey: "pageNotFoundTitle", descriptionKey: "pageNotFoundDescription" },
  { status: 404 },
);
