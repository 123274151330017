import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { BlogPost, blogPostConverter } from "./BlogPostType";
import { LoaderFunctionArgs } from "react-router-dom";

const blogPostsRef = collection(db, "blogPosts").withConverter(
  blogPostConverter,
);

// TODO: Add prop queryConstraints for ordering by publishedAt in descending order
export const getBlogPosts = async ({ params }: LoaderFunctionArgs) => {
  const { lang } = params;
  const blogPostsQuery = query<BlogPost, BlogPost>(
    blogPostsRef,
    where("language", "==", lang),
    orderBy("publishedAt", "desc"),
  );

  return getDocs(blogPostsQuery).then((snapshot) => {
    return snapshot.docs.map((doc) => doc.data());
  });
};

export const getBlogPost = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  const blogPostQuery = query<BlogPost, BlogPost>(
    blogPostsRef,
    where("slug", "==", slug),
  );

  return getDocs(blogPostQuery).then((snapshot) => {
    const blogPost: BlogPost = snapshot.docs[0].data();
    return blogPost;
  });
};
